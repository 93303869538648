import { border } from "@chakra-ui/react"

const Divider = {
   defaultProps: {
    },
    variants: {
      "checkout": {
        borderWidth: '2px', // change the width of the border
        borderStyle: 'solid', // change the style of the border
        // borderColor: 'black', // change the color of the border
        // borderRadius: 10, //
        // 'background-color': "black",
        // 'border-style': "solid",
        // 'border-bottom': '1px solid black', // Correctly specifying the border        
        // border: '1px solid black !important', // Adding a border        
        opacity: 1,
      }
    }
}

export default Divider

