import React, { setGlobal, useGlobal, useState, useEffect, useDispatch } from 'reactn'
import { Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, DrawerCloseButton } from '@chakra-ui/modal';
import { Elements, PaymentElement, useStripe, useElements, AfterpayClearpayMessageElement } from '@stripe/react-stripe-js';
// import { push as Menu } from '../../plugins/react-burger-menu/lib/BurgerMenu'
// import { isMobile } from 'react-device-detect'
import { Center, Divider, Flex, Text, useBreakpointValue, VStack } from "@chakra-ui/react"
import { navigate } from 'gatsby';
// import { useLocation } from "@reach/router"
// import { SizeMe } from 'react-sizeme'
import ShoppingCart from './shoppingCart'
import { Spinner } from '@chakra-ui/react'

import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react'

import Checkout from './checkout'
// import Checkout1 from './checkout1'
import { Box, Menu, SimpleGrid, Heading, Button } from '@chakra-ui/react'
import './cartSidebar.css'
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { formatPrice } from '../../utils/format-price';


const PaymentForm = ({ shippingValues, billingValues, sameAsShipping, globalState, setProcessing }) => {

  const { store, updateGlobal } = globalState
  const { ts, so, fetcching, fetchingCartData, showShipping, shippingError, fetchingShipping, 
    differentBillingAddress,
    cartTotal,
    cartState,
    fullName,
    contactNumber,
    emailAddress,
    billing_address,
    processPayment,
    shipping_address,
    shipping,
    showPayment,
    ip,
    cartProducts } = store    
  
  // alert(contactNumber)
  const type = useBreakpointValue(['tabs', 'tabs', 'tabs', 'tabs'])

  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  // const [processing, setProcessing] = useGlobal(false);
  // const [fetchingCartData] = useGlobal('fetchingCartData')
  const [ready, setReady] = useState(false);
  const paymentComplete = useDispatch('paymentComplete')
  // const [radarID] = useGlobal('radarID')
  const [disabled, setDisabled] = useState(true);
  // const [stripe] = useGlobal('stripe')

  const types = {
    accordion: {
      type: 'accordion',
      defaultCollapsed: false,
      radios: true,
      spacedAccordionItems: true
    },
    tabs: {
      type: 'tabs',
    }
  }

// const [paymentRequest, setPaymentRequest] = useState(false)
// const elements = stripe.elements()
const stripe = useStripe();
const elements = useElements();

// elements.create('payment', {
//   fields: {
//     billingDetails: {
//       // name: 'never',
//       // email: 'never',
//       postalCode: 'never'
//     }
//   }
// });


// const elements = useElements();
console.log("ELEMENTS", elements)
// const { cartDispatch } = useCartContext();


// const [differentBillingAddress] = useGlobal('differentBillingAddress');
// const [cartTotal] = useGlobal('cartTotal');
const differentBilling = useDispatch('differentBilling')
// const [cartState] = useGlobal('cartState')
const [section, setSection] = useState('shipping_address');
// const [cartProducts] = useGlobal('cartProducts')
// const [shipping] = useGlobal('shipping')
// const [ts] = useGlobal('ts')
// const [paymentAmount] = useGlobal('paymentAmount');
// const [processPayment, setProcessPayment] = useGlobal('processPayment')
// const [fullName, setFullName] = useGlobal('fullName')
// const [contactNumber, setContactNumber] = useGlobal('contactNumber')
// const [emailAddress, setEmailAddress] = useGlobal('emailAddress')
// const [shippingAddress] = useGlobal('shipping_address')
// const [billingAddress] = useGlobal('billing_address')

// alert("SHIPPING UPDATED")
// const billingAddress = addressData.billing_address.address
// const shippingAddress = addressData.shipping_address.address
console.log("ADDRESSXXX", shipping_address)
console.log("CART TOTAL", cartTotal)

function setProcessPayment(processPayment) {
  updateGlobal({ processPayment: processPayment })
}

const handleChange = async (event) => {
  console.log("CHANGEXXX", event)
  console.log("CHANGEXXX COMPLETE", event.complete)
  setDisabled(event.complete ? false : true);
  setError(event.error ? event.error.message : "");
}


useEffect(() => {
  if (processPayment == true) {
    setProcessPayment(false)
    handleCheckout()
  }
}, [processPayment]);

const handleCheckout = async () => {
  // ev.preventDefault();
  // setProcessing(true);
  if (!stripe || !elements) {
    // Stripe.js has not yet loaded.
    // Make sure to disable form submission until Stripe.js has loaded.
    return;
  }
  
  // const shipping_address = {
  //   line1: shippingAddress.address1,
  //   line2: shippingAddress.address2,
  //   city: shippingAddress.city,
  //   state: shippingAddress.province,
  //   postal_code: shippingAddress.zip,
  //   country: shippingAddress.country_code
  // }
  const returnURL = process.env.GATSBY_RETURN_URL

  const { error, confirmPayment } = await stripe.confirmPayment({
    elements,
    confirmParams: {
      // shipping: {
      //   address: shipping_address,
      //   name: fullName,
      //   phone: contactNumber,
      //   carrier: "Royal Mail TBD FROM SOMEWHERE"
      // },
      // // payment_method_data: {
      //   billing_details: {
      //     address: billing_address
      //     // postalCode: shippingAddress.postal_code
      //   }
      // },
      // redirect: 'if_required',
      return_url: returnURL,
      receipt_email: emailAddress
      // radar_options: {se ssion: radarID},
    },
  });
  if (error) {
    // alert("PAYMENT FAILED")
    console.log("ERROR WITH PAYMENT", error)
    if (error.type === "card_error" || error.type === "validation_error") {      
      setError(error.message);
    } else {
      setError("An unexpected error occured.");
    }
    updateGlobal({ calculating: false,processPayment: false  })
    setProcessing(false);
    setSucceeded(false)
  } else {
    // alert("GOOD PAYMENT")
    console.log("PAYMENT GOOD", confirmPayment)
    paymentComplete()
    setError(null);
    setSucceeded(true);
  }
}


return (
  <>
    {!succeeded &&
      <>
        {fetchingCartData ?
          <Box>
            <Center>
              <Dots mb={5} size='xs' />
            </Center>
          </Box>
          :
          <>
            <form onSubmit={handleCheckout} id='payment'>
              {error && (
                <Alert status='error'>
                  <AlertIcon />
                  <AlertDescription>{error}</AlertDescription>
                </Alert>
              )}
              <Center >
              <Box mt={2} maxWidth={'600px'} width="100%">
                
              <PaymentElement
                id="payment-element"
                onChange={handleChange}
                onReady={() => setReady(true)}
                options={{
                  defaultValues: {
                    billingDetails: {
                      name: fullName,
                      phone: contactNumber,
                      email: emailAddress,
                    },
                  },
                  layout: types['accordion'],
                  fields: {
                    billingDetails: {
                      address: {
                        email: 'always',
                        name: 'always',
                        phone: 'always',
                        address: 'always',
                      }
                    }
                  }
                }
                }

              />
              </Box>
              </Center>
            </form>
          </>
        }
      </>
    }
  </>
)
}

export default PaymentForm