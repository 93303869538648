import React, { setGlobal, useGlobal } from 'reactn'
import { Link } from "gatsby"
import Logo from "../icons/logo"
import { CartButton } from "./cart-button"
import { Spacer } from "@chakra-ui/layout"
import {
  Box,
  Flex,
  IconButton,
  HStack,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import SearchLogo from './search/searchLogo'

export function Header({ store, updateGlobal }) {
  const [isDrawerVisible] = useGlobal('drawerVisible')
  const [isCartVisible] = useGlobal('cartVisible')
  return (
    <Box zIndex={10} as="nav" position={'sticky'} top={0} width={'100%'} height={'56px'} bgColor={'black'}>
      <Flex>
        <HStack mt={0} mr={'20px'}>
          <Box ml={3} minW={6}>
            {isDrawerVisible ?
              <IconButton border={'none'}
                // visibility={isDrawerVisible ? 'visible' : 'hidden'}
                onClick={() => setGlobal({ sidebarOpen: true })}
                icon={<HamburgerIcon mt={2} w={6} h={6} color={'white'} />}
                variant={'unstyled'}
                _hover={{
                  background: 'none'
                }}
                aria-label={'Toggle Navigation'}
              />
              :
              <Box minW={6} />
            }
          </Box>
          <Box minW={[2, 2, 7, 7]} />
        </HStack>

        <Spacer />
        {isDrawerVisible ?
          <Link to="/" >
            <Logo />
          </Link>
          :
          <Logo />
        }
        <Spacer />
        <HStack mt={4} mr={'20px'}>
          {isDrawerVisible ?
            <SearchLogo isWhite={true} />
            :
            <Box minW={6}></Box>
          }
          {isCartVisible && <CartButton store={store} updateGlobal={updateGlobal} />}
        </HStack>
      </Flex>
    </Box>
  )
}
